// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575px) {  }

// Small devices (landscape phones, less than 768px)
@media (max-width: 767px) {  }

// Medium devices (tablets, less than 992px)
@media (max-width: 991px) {  }

// Large devices (desktops, less than 1200px)
@media (max-width: 1199px) {  }  